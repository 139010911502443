import jsPDF from 'jspdf';
import 'jspdf-autotable';
import QRCode from 'qrcode';
import JsBarcode from 'jsbarcode';
import { getImageDataUrl } from '../../utils/imageUtils';

const createBarcode = (text) => {
  const canvas = document.createElement('canvas');
  JsBarcode(canvas, text, {
    format: 'CODE128',
    width: 2,
    height: 40,
    displayValue: false
  });
  return canvas.toDataURL('image/png');
};

const generateSingleLabel = async (doc, order, warehouseData, sellerData) => {
  console.log("WH data", warehouseData);
  console.log("sellerData", sellerData);
  console.log("order", order);
  
  const defaultLogoUrl = 'https://ucarecdn.com/39bf55fc-6f5a-4411-bfda-b8aa4089bee9/-/preview/500x500/';
  // const logoUrl = sellerData.store[0]?.logo || defaultLogoUrl;
  const logoUrl = defaultLogoUrl;
  console.log("logoUrl", logoUrl);

  // Set the page margins and usable width
  const margin = 10;
  const labelWidth = 190;

  // Define fixed variables for positioning
  const shippingLine = 52;
  const shippingLineGap = 8;
  const sellerSectionGap = 8;

  // Define vertical line position (70% for address, 30% for order info)
  const verticalLineX = margin + (labelWidth * 0.7);
  const verticalLineStartY = 45;

  // Outer border
  doc.setLineWidth(1);
  doc.rect(margin, margin, labelWidth, 270);

  // Logo section
  try {
    const logoDataUrl = await getImageDataUrl(logoUrl);
    const imageType = logoDataUrl.startsWith('data:image/png') ? 'PNG' : 'JPEG';
    doc.addImage(logoDataUrl, imageType, margin + 4, 12, 45, 30);
  } catch (error) {
    console.error('Error adding logo to shipping label:', error);
    doc.setFontSize(12);
    doc.text('', margin + 4, 25);
  }

  doc.line(margin + 55, 10, margin + 55, 45);

  // Barcode Section
  const barcodeDataUrl = createBarcode(order.orderID);
  doc.addImage(barcodeDataUrl, 'PNG', margin + 80, 12.5, 80, 30);

  // Separator Line
  doc.line(margin, 45, margin + labelWidth, 45);

  // Shipping Address
  doc.setFontSize(14);
  doc.text('Ship To:', margin + 4, shippingLine);
  doc.setFont(undefined, 'bold');
  doc.setFontSize(24);
  doc.text(order.customerName, margin + 4, shippingLine + 9);
  doc.setFontSize(20);
  doc.setFont('Arial', 'normal');

  // Wrap and print the full address
  const maxWidth = verticalLineX - margin - 10;
  const wrappedAddress = doc.splitTextToSize(order.shippingAddress.fullAddress, maxWidth);
  let currentY = shippingLine + 9 + shippingLineGap;
  wrappedAddress.forEach(line => {
    doc.text(line, margin + 5, currentY);
    currentY += shippingLineGap;
  });

  // Adjust the position of subsequent address elements
  doc.text(`${order.shippingAddress.city}`, margin + 5, currentY);
  currentY += shippingLineGap;
  doc.text(`${order.shippingAddress.state}`, margin + 5, currentY);
  currentY += shippingLineGap;
  doc.text(`${order.shippingAddress.pincode}`, margin + 5, currentY);
  currentY += shippingLineGap;
  doc.text(order.shippingAddress.country, margin + 5, currentY);
  currentY += shippingLineGap;
  doc.text('Mobile: ' + order.customerPhone, margin + 5, currentY);

  // Adjust the vertical line end position
  const verticalLineEndY = Math.max(currentY + shippingLineGap, shippingLine + 25 + shippingLineGap * 4);

  doc.line(verticalLineX, verticalLineStartY, verticalLineX, verticalLineEndY);

  // Order ID and Date
  const dateOptions = { day: '2-digit', month: 'long', year: 'numeric' };
  const formattedDate = new Intl.DateTimeFormat('en-US', dateOptions).format(new Date(order.createdAt));

  const orderInfoX = verticalLineX + 2;
  doc.setFontSize(10);
  doc.setFont(undefined, 'bold');
  doc.text(`Order ID: ${order.orderID}`, orderInfoX, shippingLine + 25);
  doc.text(`Date: ${formattedDate}`, orderInfoX, shippingLine + 25 + shippingLineGap * 2);
  doc.setFont(undefined, 'normal');

  // Separator Line
  const shippingSectionEnd = verticalLineEndY + 5;
  doc.line(margin, shippingSectionEnd, margin + labelWidth, shippingSectionEnd);

  // Seller Section
  const sellerSectionTop = shippingSectionEnd + 10;
  doc.setFontSize(18);
  doc.setFont(undefined, 'bold');
  doc.text('Seller: ' + sellerData.sellerName, margin + 4, sellerSectionTop);
  doc.setFontSize(17);
  doc.setFont(undefined, 'normal');
  const sellerAddress = [
    warehouseData.name + ',' + warehouseData.address,
    `${warehouseData.city}, ${warehouseData.state || ''}, ${warehouseData.pin}, ${warehouseData.country}`
  ];
  sellerAddress.forEach((line, index) => {
    doc.text(line, margin + 4, sellerSectionTop + 10 + index * sellerSectionGap);
  });

  // Separator Line
  const sellerSectionEnd = sellerSectionTop + 10 + sellerAddress.length * sellerSectionGap + 10;
  doc.line(margin, sellerSectionEnd, margin + labelWidth, sellerSectionEnd);

  // Product Details Section
  const productTableStartY = sellerSectionEnd + 10;
  const productData = order.products.map(product => [
    product.productName.length > 20 ? product.productName.substring(0, 20) + '...' : product.productName,
    product.qty,
    `INR ${(product.unitPrice * product.qty).toFixed(2)}`
  ]);

  const maxTableHeight = 270 - productTableStartY - 60;

  doc.autoTable({
    startY: productTableStartY,
    head: [['Product', 'Qty', 'Total']],
    body: productData,
    theme: 'striped',
    headStyles: { fillColor: [255, 255, 255], textColor: [0, 0, 0], fontSize: 10 },
    styles: { fontSize: 8, cellPadding: 2, textColor: [0, 0, 0] },
    margin: { left: margin + 5, right: margin + 5 },
    tableWidth: 'auto',
    maxHeight: maxTableHeight,
  });

  // Total Amount
  const totalAmount = order.products.reduce((acc, product) => acc + (product.unitPrice * product.qty), 0).toFixed(2);
  doc.setFontSize(10);
  doc.setFont(undefined, 'bold');
  doc.text('Total: INR ' + totalAmount, margin + 120, doc.previousAutoTable.finalY + 5);

  // Separator Line
  const separatorY = doc.previousAutoTable.finalY + 10;
  doc.line(margin, separatorY, margin + labelWidth, separatorY);

  // Return Address Section
  const returnAddressStartY = separatorY + 5;
  doc.setFontSize(16);
  doc.setFont(undefined, 'normal');

  const returnAddressText = [
    warehouseData.return_address,
    `${warehouseData.return_city}, ${warehouseData.return_state}, ${warehouseData.return_pin}`,
    warehouseData.return_country,
    warehouseData.return_phone
  ];

  const lineHeight = 7;
  const startY = returnAddressStartY + 5;
  const textWidth = labelWidth - 70;

  let returnY = startY;
  returnAddressText.forEach(line => {
    const splitText = doc.splitTextToSize(line, textWidth);
    splitText.forEach(textLine => {
      doc.text(textLine, margin + 4, returnY);
      returnY += lineHeight;
    });
  });

  // QR Code
  const sellerURL = `https://prodinent.com/${sellerData.sellerID}`;
  const qrCodeDataUrl = await QRCode.toDataURL(sellerURL, { width: 100, margin: 1 });

  doc.addImage(qrCodeDataUrl, 'PNG', margin + labelWidth - 40, returnAddressStartY, 30, 30);
};

export const generateShippingLabel = async (order, warehouseData, sellerData) => {
  const doc = new jsPDF();
  await generateSingleLabel(doc, order, warehouseData, sellerData);
  doc.save('Shipping_Label_Order_' + order.orderID + '.pdf');
};

export const generateBulkShippingLabels = async (ordersWithWarehouseData, sellerData, progressCallback) => {
  const doc = new jsPDF();

  for (let i = 0; i < ordersWithWarehouseData.length; i++) {
    if (i > 0) {
      doc.addPage();
    }
    const { warehouseData, ...order } = ordersWithWarehouseData[i];
    await generateSingleLabel(doc, order, warehouseData, sellerData);
    if (progressCallback) {
      progressCallback(i + 1, ordersWithWarehouseData.length);
    }
  }

  doc.save('Bulk_Shipping_Labels.pdf');
};